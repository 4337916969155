import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useCallback
} from 'react';
import { useLogout, useRefresToken } from '@src/queries/aut';
import { useNavigate } from 'react-router-dom';
import { Path } from '@src/navigations/routes';
import { isUndefined } from '@src/utils/type';
import ClientApi from '@src/types/client';
import { useToasts } from '../toast-context';
import { USER_STORAGE_KEY } from '@src/constants';
import { getFullName, getInitials } from '@src/utils/user';
import { clearState, setState } from '@src/utils/stateStorage';
type AuthProviderProps = {
  children: ReactNode;
};

type AuthContextType = {
  userName: string;
  Userpassword: string;
  isLoggedIn: boolean;
  handleLoginSuccess: (userData: ClientApi.General.LoggedInUser['Data']) => void;
  user: ClientApi.General.LoggedInUser['Data'] | undefined;
  isUserSuperAdmin: boolean;
  isUserTeamLead: boolean;
  isUserTeamMember: boolean;
  handleLogout: () => Promise<void>;
  logoutLoading: boolean;
  userRole: string;
  isUserCompliance: boolean;
  userFullName: string;
  userInitials: string;
  handleLogoutSuccess: () => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<ClientApi.General.LoggedInUser['Data']>();
  const [isLoggedIn, setIsLoggedIn] = useState(!!sessionStorage.getItem(USER_STORAGE_KEY));

  const { addToast } = useToasts();
  const navigate = useNavigate();
  const DURATION_4_MIN = 4 * 60 * 1000;
  const { mutateAsync: logoutMutate, isLoading: logoutLoading } = useLogout();

  useEffect(() => {
    handleUpdateUser();
  }, []);

  useRefresToken({
    enabled: isLoggedIn,
    refetchIntervalInBackground: true,
    refetchInterval: DURATION_4_MIN,
    staleTime: DURATION_4_MIN,
    retry: false,
    onSuccess: (data) => {
      handleUpdateToken(data);
    }
  });

  const handleUpdateUser = () => {
    try {
      const userInStorage = sessionStorage.getItem(USER_STORAGE_KEY);

      if (userInStorage) {
        const parsedUser = JSON.parse(userInStorage) as ClientApi.General.LoggedInUser['Data'];
        setUser(parsedUser);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const handleUpdateToken = (token: string) => {
    const updatedUser = { ...user, SessionToken: token } as ClientApi.General.LoggedInUser['Data'];

    const stringifiedUser = JSON.stringify(updatedUser);
    sessionStorage.setItem(USER_STORAGE_KEY, stringifiedUser);
    setUser(updatedUser);
  };

  const handleLoginSuccess = (userData: ClientApi.General.LoggedInUser['Data']) => {
    try {
      setState(userData);
      setUser(userData);
      setIsLoggedIn(true);
      addToast({ body: 'Login Successful', title: 'Success' });
      navigate(Path.Dashboard);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const handleLogoutSuccess = () => {
    setUser(undefined);
    setIsLoggedIn(false);
    clearState();
    navigate(Path.Login);
  };

  const handleLogout = useCallback(async () => {
    try {
      await logoutMutate();

      handleLogoutSuccess();
      addToast({ body: 'Logout successful', title: 'Success' });
      // eslint-disable-next-line no-empty, @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorMessage = error?.ResponseMessage;

      if (errorMessage === 'Invalid session token.') {
        handleLogoutSuccess();
      }
    }
  }, []);

  const userRole = user?.RoleName || '';
  const isUserSuperAdmin = userRole === 'Super Admin';
  const isUserTeamLead = userRole === 'Team Lead';
  const isUserTeamMember = userRole === 'Team Member';
  const isUserCompliance = userRole === 'Compliance';

  const firstName = user?.FirstName || '';
  const lastName = user?.LastName || '';
  const userFullName = getFullName({ firstName, lastName });
  const userInitials = getInitials(userFullName);

  const values: AuthContextType = {
    userName: '',
    Userpassword: '',
    isLoggedIn,
    handleLoginSuccess,
    user,
    isUserSuperAdmin,
    isUserTeamLead,
    handleLogout,
    logoutLoading,
    isUserTeamMember,
    userRole,
    isUserCompliance,
    userFullName,
    userInitials,
    handleLogoutSuccess
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAccount = () => {
  const context = useContext(AuthContext);
  if (isUndefined(context)) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};
